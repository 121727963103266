@import "../../App.scss";

.sign-page {
  height: 100vh;
  width: 100%;
  background-image: url("../images/mehzen/login-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;

  &-background {
    background: rgba($color: #000000, $alpha: 0.7);
    height: 100vh;
    width: 100%;

    &-row {
      width: 100%;
    }

    &-mahzen-logo img {
      height: 113px;
    }
  }
}

.mahzen-logo img {
  height: 113px;
}

.siginup-inner {
  background: #ffffff;
  border: 1px solid #e4e3e4;
  border-radius: 10px;
}

.signup-text1 {
  font-family: $Montserrat-Regular;
  font-style: normal;
  font-size: $font-size22;
  line-height: 33px;
  color: #193f52;
}

.signup-text2 {
  font-family: $Montserrat-Regular;

  font-weight: 600;
  font-size: 30px;
  line-height: 37px;
  color: #000;
}

.custome_offset {
  width: 50%;

  @media (max-width: 1280px) {
    width: 80%;
  }
}

.forgot-text2 {
  font-family: $Montserrat-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: $primary-color;
}

.eyeBtn {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 100%;
}

.signup-input-label p {
  font-family: $Montserrat-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: $primary-color;
}

.signup-staric-color {
  margin-top: 5px;
  padding-left: 5px;
}

.signup-input-label input[type="text"],
.signup-input-label input[type="password"] {
  width: 100%;
  height: 54px;
  padding-left: 1.3%;
  border: 1px solid $primary-color;
  border-radius: 8px;

  &:focus {
    outline: none;
  }
}

.border_custome {
  border: 1px solid $primary-color;
}

.sdfgsdg::placeholder {
  font-size: 5px;
  padding-top: 3%;
}

.signup-input-label-input input {
  padding-left: 2.3%;
}

.PleaseAcceptCheckbox {
  font-family: $Montserrat-Regular !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  margin-top: 0.3% !important;
  color: $primary-color !important;
  padding-top: 0%;
}

.PleaseAcceptCheckbox span {
  color: #193f52;
}

.PleaseAcceptCheckboxSignin,
.PleaseAcceptCheckboxSignin:hover {
  font-family: $Montserrat-Regular !important;
  font-style: normal !important;
  font-weight: 600 !important;

  line-height: 21px !important;
  margin-top: 0.3% !important;
  color: $primary-color !important;
  text-decoration: none;
}

.resgister-button button {
  width: 100%;
  height: 54px;
  background: #000;
  border-radius: 40px;
  border: none;
  font-family: $Montserrat-Regular;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  text-align: center;
  margin-top: 1.6rem;
}

.resgister-button button:focus {
  outline: none;
}

.signup-lower-text {
  p {
    font-family: $Montserrat-Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;

    color: $primary-color;
  }

  span {
    a {
      color: #193f52;
      font-family: $Poppins-Bold;
      font-size: 11px;
    }
  }
}

.sign-text,
.sign-text:hover {
  font-family: $Montserrat-Regular;
  font-style: normal;
  font-size: 11px;
  line-height: 16px;
  color: #193f52;
  font-weight: 600;
  text-decoration: none;
}

.mahzen-link,
.mahzen-link:hover {
  text-decoration: none;
}

.Checkboxlabel:focus,
.CheckboxInput:focus {
  outline: none;
}

.signup-staric-color {
  color: #ec0303 !important;
}

@media (min-height: 600px) and (max-height: 650px) {
  .signup-input-label-accept {
    margin-top: 3% !important;
  }

  .signup-input-label-input-top {
    margin-top: -9px !important;
  }
}

.error_message {
  font-size: 0.78vw;
  color: red;

  @media (max-width: 480px) {
    font-size: 2.3vw;
  }
}

@media screen and (max-width: 480px) {
  .PleaseAcceptCheckboxSignin {
    font-size: 12px;
  }
}
