.parent-box {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
}

.content-box {
  width: 97%;
  height: auto;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 2rem;
  margin-top: 1rem;
}

.event-image {
  width: 100%;
  height: 263px;
  border-radius: 30px 30px 0 0;
}

.event-head {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .event-timer {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    color: #e90c0c;
    margin-bottom: 0px;
  }

  .event-name {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: #0d0d0d;
    margin-bottom: 0px;
  }

  .verification {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: #0d0d0d;
    margin-bottom: 0px;
  }
}

.event-details {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .icons-main {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding-left: 20px;

    .event-detail-icon {
      width: 20px;
      height: 20px;
    }
  }
}

.detail-description {
  margin-bottom: 0px;
  margin-top: -4px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 26.21px;
  text-align: left;
  color: #999999;
}
