.card-text {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600 !important;
  line-height: 17.07px;
  text-align: right;
  color: #000000;
  margin-top: 10px;
}
.bar-chart-text1 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;
  letter-spacing: 1px;
  text-align: center;
}
