.container {
    margin-top: 20px;
  }
  
  .table-custom {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table-custom th,
  .table-custom td {
    padding: 20px;
    text-align: left;
  }
  
  .table-custom th {
    font-weight: bold;
    border-bottom: 1px solid #ddd;
  }
  
  
  .table-custom td:last-child {
    text-align: right;
  }
  
  .table-custom tr {
    border-bottom: 1px solid #ddd;
  }

  .table-custom tr:last-child{
    border-bottom: 1px solid transparent;
  }
  
  .h2-custom {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    text-align: left;
    color: #999999;
    }
    hr {
        border-top: 1px solid #e0e0e0; /* Light gray color for the line */
      }
  
  .month-name {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.94px;
    text-align: left;
    color :#000000
  }

.amount {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
    text-align: center;
    color:#000000
}

.filter-modal-text {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;
  text-align: left;
}