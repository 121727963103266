.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1050;
  }
  
  .modal-confirm {
    max-width: 500px;
    width: 100%;
  }
  
  .modal-content {
    border: none
  }