.cover-image {
    width: 100%;
    height: 202px;
    background-color: #E0E0E0;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 6px;
    background-repeat: no-repeat;

    .banner-cover {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 6px;
    }

    .inner-cover {
        position: absolute;
        left: 49%;
        top: 15%;
    }

    .camera-cover {
        position: absolute;
        right: 2.5%;
        bottom: 20%;
    }
}

.profile-image {
    position: absolute;
    border: 4px solid white;
    border-radius: 100%;
    width: 120px;
    height: 120px;
    background-color: #E0E0E0;
    bottom: -20%;
    left: 45%;
    display: flex;
    justify-content: center;
    align-items: center;

    .preview-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%;
    }

    .upload-profile {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        .profile-camera {
            position: absolute;
            right: -15%;
            bottom: 28%;
        }
    }
}

.uni-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    justify-content: start;

    .uni-input {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .uni-heading {
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 600;
        color: #0D0D0D;
    }

    .uni-text-area {
        border: 1px solid black;
        height: 116px;
        border-radius: 8px;
        width: 100%;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        color: black;
    }
}

.uni-name {
    border: 1px solid black;
    height: 54px;
    border-radius: 8px;
    width: 100%;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    color: black;
}

.grid-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.course-section {
    width: 100%;
    height: 260px;
    margin-top: 20px;
    border-radius: 6px;
    border: 1px;
    border-color: #DDDDDD;
    background-color: white;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .course-heading {
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 600;
        color: #0D0D0D;
    }

    .course-suggestions {
        width: 100%;
        height: 100px;
        background-color: #F6F6F6;
        border-radius: 12px;
        padding: 8px 14px 14px 10px;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .suggestions {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 400;
        color: #999999;
    }

    .suggestion-box {
        width: 150px;
        height: 30px;
        background-color: #E9E9E9;
        display: flex;
        justify-content: center;
        border-radius: 18px;

        .suggestions-text {
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 400;
            color: #999999;
            margin-top: 2px;
        }
    }
}

.course-input {
    display: flex;
    align-items: center;
    border: 1px solid black;
    padding: 5px;
    border-radius: 8px;
    margin-top: -15px;
}

.course-input-inner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 1;
    gap: 3px;
}

.course-name {
    flex: 1;
    border: none;
    outline: none;
    padding: 5px;
    margin-right: 5px;
}

.course-tag {
    display: flex;
    align-items: center;
    background-color: #e0e0e0;
    padding: 5px 10px;
    border-radius: 5px;
    margin-right: 5px;
    cursor: pointer;
}

.remove-course-button {
    background: none;
    border: none;
    margin-top: -2px;
    margin-left: 5px;
    cursor: pointer;
    color: red;
}

.hosts {
    height: auto !important;
    // overflow-y: scroll !important;
}

.search-container {
    position: relative;
    width: 100%;
}

.search-hosts {
    width: 100%;
    height: 44px !important;
    background-color: #F6F6F6;
    padding-left: 35px;
    padding-right: 10px;
    border: 1px solid #DDDDDD;
    border-radius: 8px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    color: black;
}

.search-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    font-size: 20px;
    color: #999999;
}
.hosts-container {
display: flex;
flex-direction: row;
gap: 15px;
cursor: pointer;
}
.image-parent {
    width: 49px;
    height: 49px;
    border-radius: 100%;
    background-color: #DDDDDD;
    position: relative;
}
.user-image {
    width: 49px;
    height: 49px;
    border-radius: 100%;
    position: absolute;
}
.user-name{
    font-family: Montserrat;
font-size: 14px;
font-weight: 600;
line-height: 17.07px;
text-align: left;
color: #0D0D0D;
margin-top: 15px;

}

.add-button {
    width: 296px;
    height: 54px;
    border-radius: 40px;
    background-color: #0D0D0D;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: white;
    margin-top: 20px;
}
