.doc-review-pic {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
}

.review-name-detail {
  font-family: "Poppins Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  color: #535353;
}

.slick-dots li button:before {
  font-size: 11px !important;
}
